<template>
	<b-modal :no-close-on-backdrop="true" :hide-header-close="isLoading" ref="modal" :title="title" :size="modalSize" class="modal-primary" @hidden="clearModalData">
		<div>
			<CRow>
				<CCol>
					<CInput
						label="Ime"
						v-model="modalUser.name"/>
				</CCol>
				<CCol>
					<CInput
						label="Email"
						v-model="modalUser.email"
						:isValid="isValidEmail"
						:invalid-feedback="emailInvalidFeedback"
						:disabled="mode === mode_EDIT2"/>
				</CCol>
			</CRow>
			<CRow>
				<CCol>
					<CInput
						label="Geslo"
						v-model="modalUser.password"
						type="password"
						:is-valid="isPasswordValid"
						:invalid-feedback="passwordInvalidFeedback"/>
				</CCol>
				<CCol>
					<CInput
						label="Potrditev gesla"
						type="password"
						:is-valid="isPasswordConfirmationValid"
						:invalid-feedback="passwordConfirmationInvalidFeedback"
						:disabled="!modalUser.password"
						v-model="modalUser.passwordConfirmation"/>
				</CCol>
			</CRow>
			<CRow>
				<CCol>
					<CSelect
						prepend="Vloga:"
						:options="roles"
						:value.sync="modalUser.role"
						custom
					/>
				</CCol>
			</CRow>
			<b-card header="Dovoljenja">
				<CBadge v-for="permission in editablePermissions" :key="permission.id" :color="modalUser.permissions.includes(permission)  ? 'primary' : 'danger'" class="float-left mr-2 mb-1">
					<span>{{ permission }}</span>
					<CLink  outline :variant="modalUser.permissions.includes(permission) ? 'primary' : 'danger'" :title="modalUser.permissions.includes(permission) ? 'Odstrani pravico' : 'Dodaj pravico'" href="#" class="card-header-action btn-setting" @click.stop="click_permissionToggle(permission)">
						<CIcon  :name="modalUser.permissions.includes(permission) ? 'cil-x' : 'cil-check-alt'"/>
					</CLink>
				</CBadge>
			</b-card>
		</div>
		<CAlert v-show="message.text != null" :color="message.type">
			{{ message.text }}
		</CAlert>
		<template slot="modal-footer" slot-scope="{ cancel }">
			<b-container fluid class="p-0">
				<b-row>
					<b-col align-self="end" class="ma_pos">
						<b-button :disabled="isLoading" style="margin-right:5px;" variant="secondary" @click="cancel()">Prekliči</b-button>
						<b-button :disabled="okButton.disabled || !isFormValid" :title="isFormValid ? '' : 'Preverite vnos podatkov'" variant="primary" @click.stop="onOkModal()">{{ okButton.label }}</b-button>
					</b-col>
				</b-row>
			</b-container>
		</template>
		<vue-loading :active.sync="isLoading" :is-full-page="false"/>
	</b-modal>
</template>

<script>
import settings from '@/settings.js';
import { ROLES_LIST_ALL } from "@/store/actions/roles";
import { USER_CREATE_EDIT } from "@/store/actions/users";

export default {
	name: 'user-create-edit-modal',
	components: {
	},
	props: {
	},
	methods: {
		showModal: function (modalInput) {
			/*
				mode 1 => Create
				mode 2 => Edit
			*/
			this.mode = modalInput.mode;
			this.initModal(modalInput.modalUser);
		},
		initModal: function (modalUser) {
			if (this.mode === this.mode_CREATE1) {
				this.title = 'Dodajanje uporabnika';
				this.okButton.label = 'Dodaj';
			} else if (this.mode === this.mode_EDIT2) {
				this.title = 'Urejanje uporabnika ' + modalUser.name + ' (#' + modalUser.id + ')';
				this.initUser(modalUser);
				this.okButton.label = 'Shrani';
			} else {
				this.mode = this.mode_UNKNOWN0;
				this.title = 'Neznan način';
				this.okButton.label = 'Zapri';
			}
			this.$refs.modal.show();
		},
		initUser: function (modalUser) {
			this.modalUser.id = modalUser.id;
			this.modalUser.name = modalUser.name;
			this.modalUser.email = modalUser.email;
			for(let perm of modalUser.allPermissionsNames) {
				if (this.editablePermissions.find((item) => item === perm)) {
					this.modalUser.permissions.push(perm);
				}
			}
			if (modalUser.roles && modalUser.roles.length > 0) {
				this.modalUser.role = modalUser.roles[0].name;
			}
		},
		onOkModal: function () {
			this.startProgress();
			const modalUser = this.modalUser;
			this.$store.dispatch(USER_CREATE_EDIT, { modalUser })
				.then((responseData) => {
					this.stopProgress();
					this.$emit('emit_userCreatedOrEdited', responseData);
					this.$refs.modal.hide();
				})
				.catch(error => {
					this.stopProgress();
					console.error(error);
					if(error.response && error.response.data && error.response.data.code) {
						if (this.mode === this.mode_CREATE1) {
							this.showAlert('Napaka pri dodajanju! (' + error.response.data.code + ' - ' +  error.response.data.message + ')', this.ALERT_TYPE_ERROR);
						} else if (this.mode === this.mode_EDIT2) {
							this.showAlert('Napaka pri urejanju! (' + error.response.data.code + ')', this.ALERT_TYPE_ERROR);
						} else {
							this.showAlert('Neznan način modala! (' + error.response.data.code + ')', this.ALERT_TYPE_ERROR);
						}
					} else {
						this.showAlert('Napaka! Prosimo poizkusite kasneje.', this.ALERT_TYPE_ERROR);
					}
				});
		},
		clearModalData: function () {
			this.isLoading = false;
			this.title = '';
			this.okButton = {
				label: 'Shrani',
				progress: false,
				disabled: false
			};
			this.showAlert();
			this.mode = 0;
			this.modalUser.id = 0;
			this.modalUser.name = '';
			this.modalUser.email = '';
			this.modalUser.password = '';
			this.modalUser.passwordConfirmation = '';
			this.modalUser.role = 'secretary';
			this.modalUser.permissions = [];
		},
		startProgress: function () {
			this.isLoading = true;
			this.okButton.disabled = true;
			this.okButton.progress = true;
		},
		stopProgress: function () {
			this.isLoading = false;
			this.okButton.disabled = false;
			this.okButton.progress = false;
		},
		showAlert: function (message, type, seconds) {
			if (message && type && seconds) {
				this.message.type = type;
				this.message.text = message;
				setTimeout(() => {
					this.showAlert();
				}, seconds * 1000);
			} else if (message && type) {
				this.message.type = type;
				this.message.text = message;
			} else if (message) {
				this.message.type = 'primary';
				this.message.text = message;
			} else {
				this.message.type = null;
				this.message.text = null;
			}
		},
		click_permissionToggle: function (permission) {
			if (this.modalUser.permissions.indexOf(permission) === -1) {
				this.modalUser.permissions.push(permission)
			} else {
				this.modalUser.permissions = this.modalUser.permissions.filter(e => e !== permission);
			}
		}
	},
	data: function () {
		return {
			isLoading: false,
			title: '',
			okButton: {
				label: 'Shrani',
				progress: false,
				disabled: false
			},
			message: {
				type: null,
				text: null
			},
			ALERT_TYPE_SUCCESS: 'success',
			ALERT_TYPE_ERROR: 'danger',
			mode_UNKNOWN0: 0,
			mode_CREATE1: 1,
			mode_EDIT2: 2,
			mode: 0,
			modalUser: {
				id: 0,
				name: '',
				email: '',
				role: 'secretary',
				password: '',
				passwordConfirmation: '',
				permissions: []
			},
			allPossiblePermissions: [],
			editablePermissions: [
				'CDP-read',
				'CDP-write',
				'Toyota-read',
				'Toyota-write',
				'Form.NET-read',
				'Form.NET-write',
				'Vasco-read',
				'Vasco-write'
			]
		}
	},
	watch: {
	},
	computed: {
		roles() {
			return this.$store.getters.getAllRoles
		},
		modalSize () {
			return 'md';
		},
		isFormValid() {
			if (this.mode === this.mode_CREATE1 && this.isValidEmail === true && this.isPasswordValid === true && this.isPasswordConfirmationValid === true) {
				return true;
			} else if (this.mode === this.mode_EDIT2 && (this.isPasswordValid === true || this.isPasswordValid === null) && (this.isPasswordValid === true ? this.isPasswordConfirmationValid === true : (this.isPasswordConfirmationValid === true || this.isPasswordConfirmationValid === null))) {
				return true;
			}
			return false;
		},
		isValidEmail() {
			if (!this.modalUser.email || this.mode === this.mode_EDIT2) {
				return null;
			} else if (settings.emailRegex.test(this.modalUser.email)) {
				return true;
			} else {
				return false;
			}
		},
		emailInvalidFeedback() {
			return 'Nepravilna oblika emaila.';
		},
		isPasswordValid() {
			if (!this.modalUser.password) {
				return null;
			} else if (this.modalUser.password && this.modalUser.password.length > 5) {
				return true;
			} else {
				return false;
			}
		},
		passwordInvalidFeedback() {
			return 'Geslo mora vsebovati vsaj 6 znakov.';
		},
		isPasswordConfirmationValid() {
			if (!this.modalUser.password || !this.modalUser.passwordConfirmation) {
				return null;
			} if (this.isPasswordValid && this.modalUser.password === this.modalUser.passwordConfirmation) {
				return true;
			} else {
				return false;
			}
		},
		passwordConfirmationInvalidFeedback() {
			if (this.isPasswordValid) {
				return 'Gesli se ne ujemata';
			} else {
				return 'Geslo mora vsebovati vsaj 6 znakov.';
			}
		},
	},
	created () {
		this.$store.dispatch(ROLES_LIST_ALL);
	}
}
</script>

<style scoped>
.ma_pos {
	text-align: end;
}
</style>